import React from "react"
import styled from "@emotion/styled"
import { isBrowser } from "../../../context/ApolloContext"
import Slider from "react-slick"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { injectIntl } from "gatsby-plugin-intl"

const RightArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button next"
      type="button"
      aria-label="Next"
      onClick={onClick}
    >
      <FaAngleRight size={30} />
    </button>
  )
}

const PrevArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button prev"
      type="button"
      aria-label="Previous"
      onClick={onClick}
    >
      <FaAngleLeft size={30} />
    </button>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  variableWidth: true,
  slidesToScroll: 1,
  nextArrow: <RightArrows />,
  prevArrow: <PrevArrows />,
}

const LyneHomeMaisonSlider = ({ intl, data }) => {
  return (
    <LyneHomeMaisonSliderWrapper>
      {isBrowser && (
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <MaisonBox key={index.toString()}>
                {item.image && (
                  <MaisonImage className="lazyload" data-src={item.image} />
                )}
                {item.title && (
                  <MaisonTitle
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                )}
                {item.text && (
                  <MaisonText dangerouslySetInnerHTML={{ __html: item.text }} />
                )}
              </MaisonBox>
            )
          })}
        </Slider>
      )}
    </LyneHomeMaisonSliderWrapper>
  )
}

const LyneHomeMaisonSliderWrapper = styled.div`
  display: flex;
  width: 100%;

  .slick-slider {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  .slick-dots {
    bottom: -39px;
  }
  .slick-dots li {
    width: 15px;
    height: 15px;
  }
  .slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0px;
  }
  .slick-dots li button:before {
    width: 15px;
    height: 15px;
    font-size: 10px;
    line-height: 15px;
  }
  .slider-prev-next-button {
    border: 2px solid #262626;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    bottom: -45px;
    z-index: 1;
  }
  .slider-prev-next-button.prev {
    left: 35%;

    @media (max-width: 767px) {
      left: 25%;
    }

    @media (max-width: 600px) {
      left: 0px;
    }
  }
  .slider-prev-next-button.next {
    right: 35%;

    @media (max-width: 767px) {
      right: 25%;
    }

    @media (max-width: 600px) {
      right: 0px;
    }
  }
`

const MaisonBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 260px;
  max-width: 260px;
  width: 100%;
  margin: 0px 30px 0px 0px;
`

const MaisonImage = styled.img`
  width: 100%;
  margin: 0px 0px 25px;
  object-fit: contain;
`

const MaisonTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
`

const MaisonText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "GothamLight";
  color: #262626;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;

  @media (max-width: 850px) {
    padding: 0px;
  }

  .flickity-viewport {
    @media (max-width: 550px) {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
    }
  }

  .flickity-slider {
    display: flex;
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;
    top: 100%;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button {
    transform: translate(-50%, -50%);
    bottom: -60px;
    top: auto;
  }
  .flickity-prev-next-button.next {
    right: 35%;

    @media (max-width: 850px) {
      right: -20px;
    }
  }
  .flickity-prev-next-button.previous {
    left: 38%;

    @media (max-width: 850px) {
      left: 20px;
    }
  }
  .flickity-page-dots {
    bottom: -30px;
    max-width: 160px;
    margin: 0px auto;
    left: 0;
    right: 0;
  }

  .flickity-page-dots .dot {
    margin: 0 3px;
  }
`

export default injectIntl(LyneHomeMaisonSlider)
