import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import LyneHomeActivitySlider from "../LyneHomeActivitySlider"

const LyneHomeActivitySection = ({ intl, data }) => {
  return (
    <LyneHomeActivitySectionWrapper>
      <LyneHomeActivitySectionBox>
        {data.title && (
          <LyneHomeActivitySectionTitle
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}
        {data.list && <LyneHomeActivitySlider data={data.list} />}
      </LyneHomeActivitySectionBox>
    </LyneHomeActivitySectionWrapper>
  )
}

const LyneHomeActivitySectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 60px;
  background: #e5e5e5;

  @media (max-width: 550px) {
    padding: 40px 20px 60px;
  }
`

const LyneHomeActivitySectionBox = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1080px;
`

const LyneHomeActivitySectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 850px) {
    font-size: 36px;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

export default injectIntl(LyneHomeActivitySection)
