import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductTopBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #c0dbee;
  color: #262626;
  height: 400px;

  @media (max-width: 1080px) {
    height: 350px;
  }

  @media (max-width: 980px) {
    height: 320px;
  }

  @media (max-width: 550px) {
    background: #c0dbee;
    height: auto;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 20px;
  max-width: 1200px;
  width: 100%;
  height: 100%;

  @media (max-width: 550px) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 20px;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 20px 0px 0px;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 850px) {
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    padding: 40px 0px 20px;
  }
`

const DesktopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  object-position: left center;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
  object-position: left center;
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 0px 0px 20px;
  position: relative;

  @media (max-width: 850px) {
    padding: 0px 0px 0px 10px;
  }

  @media (max-width: 550px) {
    width: calc(100% + 40px);
    max-width: inherit;
    margin-left: -20px;
    padding: 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  padding: 0px;
  justify-content: center;

  @media (max-width: 1080px) {
    max-width: 100%;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

const Title = styled.div`
  color: #262626;
  font-size: 21px;
  margin: 0px;
  line-height: 1;
  font-family: "Gotham Book";
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    width: 100%;
    align-items: center;
    font-size: 18px;
  }
`

const BottomText = styled.div`
  font-family: "GothamLight";
  font-weight: bold;
  font-size: 18px;
  line-height: ${({ lang }) => (lang === "en" ? "1.3" : "1")};
  color: #262626;
  width: 100%;
  text-transform: ${({ lang }) => (lang === "en" ? "inherit" : "uppercase")};

  p {
    margin: 0px;
  }

  ul {
    list-style: none;
    margin: 0px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  span {
    color: #c0dbee;
    background: #262626;
    border-radius: 50%;
    margin-right: 8px;
    max-width: 26px;
    width: 100%;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 850px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  min-height: 25px;

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const LogoImage = styled.img`
  width: 100%;
  margin: 0px;
  max-width: 170px;
  margin-right: 20px;
  object-fit: contain;

  @media (max-width: 550px) {
    margin-right: 15px;
    max-width: 150px;
  }

  @media (max-width: 400px) {
    max-width: 150px;
  }
`

const TopText = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  padding: 10px 15px;
  background: #262626;
  border-radius: 15px 0px 15px 0px;

  @media (max-width: 360px) {
    font-size: 16px;
    padding: 8px 12px;
  }
`

export const ProductTopBanner = ({ intl, data }) => {
  return (
    <ProductTopBannerWrapper>
      <ContentWrapper>
        <LeftWrapper>
          <TextWrapper>
            {data.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.logo_image && (
              <LogoWrapper>
                {data.logo_image && (
                  <LogoImage src={data.logo_image} />
                )}
                {/* {data.tag && (
                  <TopText
                    dangerouslySetInnerHTML={{
                      __html: data.tag,
                    }}
                  />
                )} */}
              </LogoWrapper>
            )}
            {data.text && (
              <BottomText
                dangerouslySetInnerHTML={{
                  __html: data.text,
                }}
                lang={intl.locale}
              />
            )}
          </TextWrapper>
        </LeftWrapper>
        <RightWrapper>
          {data.desktop_image && (
            <DesktopImage className="lazyload" data-src={data.desktop_image} />
          )}
          {data.mobile_image && (
            <MobileImage className="lazyload" data-src={data.mobile_image} />
          )}
        </RightWrapper>
      </ContentWrapper>
    </ProductTopBannerWrapper>
  )
}

export default injectIntl(ProductTopBanner)
