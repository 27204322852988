import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import LyneHomeVideo from "../../../../static/LyneHome.mp4"

const LyneHomeUtilisationSection = ({ intl, data, gifImage }) => {
  return (
    <LyneHomeUtilisationSectionWrapper>
      <LyneHomeUtilisationBox>
        <LeftWrapper>
          <video
            loop={true}
            autoPlay={true}
            muted
            playsInline
            width="100%"
            style={{
              objectPosition: "50% 50%",
              objectFit: "cover",
            }}
          >
            <source src={LyneHomeVideo} type="video/mp4" />
            {/* <source
              src="https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles-derniere-chance/solutions_textiles_derniere_chance_-_video_4_gilet_femme.mp4"
              type="video/mp4"
            /> */}
            Your browser does not support the video tag.
          </video>
          {/* {gifImage && <Image src={gifImage} />} */}
        </LeftWrapper>
        <RightWrapper>
          <TextWrapper>
            {data.title && (
              <LyneHomeUtilisationTitle
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
            )}
            {data.text && (
              <BottomText dangerouslySetInnerHTML={{ __html: data.text }} />
            )}
            {data.list && (
              <TestimonialWrapper>
                {data.list.map((item, index) => {
                  return (
                    <TestimonialBox key={index.toString()}>
                      {item.image && (
                        <TestimonialImage
                          className="lazyload"
                          data-src={item.image}
                        />
                      )}
                      {item.text && (
                        <TestimonialText
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </TestimonialBox>
                  )
                })}
              </TestimonialWrapper>
            )}
          </TextWrapper>
        </RightWrapper>
      </LyneHomeUtilisationBox>
    </LyneHomeUtilisationSectionWrapper>
  )
}

const LyneHomeUtilisationSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 40px;
  background: #e5e5e5;
`

const LyneHomeUtilisationBox = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1080px;
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 20px 0px 0px;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 850px) {
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    padding: 0px 0px 20px;
  }

  video {
    border-radius: 80px 0px;
    overflow: hidden;

    @media (max-width: 420px) {
      border-radius: 50px 0px;
    }
  }
`

const Image = styled.img`
  object-fit: contain;
  margin: 0px;
  object-position: center center;

  @media (max-width: 550px) {
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 0px 0px 20px;
  position: relative;

  @media (max-width: 850px) {
    padding: 0px 0px 0px 10px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    padding: 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  padding: 0px;
  justify-content: center;

  @media (max-width: 1080px) {
    max-width: 100%;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

const LyneHomeUtilisationTitle = styled.h2`
  font-size: 40px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  margin-bottom: 10px;

  @media (max-width: 850px) {
    font-size: 36px;
  }
`

const BottomText = styled.div`
  font-family: "GothamLight";
  font-size: 16px;
  line-height: 21px;
  color: #262626;
  width: 100%;

  @media (max-width: 850px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }
`

const TestimonialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 0px;
  width: 100%;
`

const TestimonialBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  @media (max-width: 550px) {
    padding: 5px;
  }
`

const TestimonialImage = styled.img`
  object-fit: contain;
  margin-bottom: 8px;
  width: 70px;
  min-height: 52px;
`

const TestimonialText = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  font-family: "Gotham";
  text-align: center;

  @media (max-width: 550px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

export default injectIntl(LyneHomeUtilisationSection)
