import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const LyneHomeActionSection = ({ intl, data, background }) => {
  return (
    <LyneHomeActionSectionWrapper background={background}>
      <LyneHomeActionContainer>
        {data.title && (
          <SectionTitle dangerouslySetInnerHTML={{ __html: data.title }} />
        )}
        {data.text && <Text dangerouslySetInnerHTML={{ __html: data.text }} />}
        {data.desktop_image && (
          <DesktopImage className="lazyload" data-src={data.desktop_image} />
        )}
        {data.mobile_image && (
          <MobileImage className="lazyload" data-src={data.mobile_image} />
        )}
      </LyneHomeActionContainer>
    </LyneHomeActionSectionWrapper>
  )
}

const LyneHomeActionSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px 30px;
  background: ${({ background }) => (background ? background : "#fcfcfc")};
  position: relative;
`

const LyneHomeActionContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  margin-bottom: 15px;

  @media (max-width: 850px) {
    font-size: 36px;
  }
`

const Text = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "GothamLight";
  color: #262626;
  margin-bottom: 15px;
`

const DesktopImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
  max-width: 680px;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

export default injectIntl(LyneHomeActionSection)
