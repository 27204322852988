import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import LyneHomeMaisonSlider from "../LyneHomeMaisonSlider"

const LyneHomeMaisonSection = ({ intl, data }) => {
  return (
    <LyneHomeMaisonSectionWrapper>
      <LyneHomeMaisonSectionBox>
        {data.title && (
          <LyneHomeMaisonSectionTitle
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}
        {data.list && (
          <DesktopMaisonWrapper>
            {data.list.map((item, index) => {
              return (
                <MaisonBox key={index.toString()}>
                  {item.image && (
                    <MaisonImage className="lazyload" data-src={item.image} />
                  )}
                  {item.title && (
                    <MaisonTitle
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  )}
                  {item.text && (
                    <MaisonText
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  )}
                </MaisonBox>
              )
            })}
          </DesktopMaisonWrapper>
        )}
        {data.list && (
          <MobileMaisonWrapper>
            <LyneHomeMaisonSlider data={data.list} />
          </MobileMaisonWrapper>
        )}
      </LyneHomeMaisonSectionBox>
    </LyneHomeMaisonSectionWrapper>
  )
}

const LyneHomeMaisonSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px;
  background: #c0dbee;

  @media (max-width: 850px) {
    padding: 40px 20px 60px;
  }
`

const LyneHomeMaisonSectionBox = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1080px;
`

const LyneHomeMaisonSectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 25px;

  @media (max-width: 850px) {
    font-size: 36px;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

const DesktopMaisonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobileMaisonWrapper = styled.div`
  display: none;
  justify-content: space-between;

  @media (max-width: 850px) {
    display: flex;
  }
`

const MaisonBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 325px;
  max-width: 325px;
  width: 100%;
  margin: 0px;

  @media (max-width: 1080px) {
    min-width: 260px;
    max-width: 260px;
  }
`

const MaisonImage = styled.img`
  width: 100%;
  margin: 0px 0px 25px;
  object-fit: contain;
`

const MaisonTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
`

const MaisonText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "GothamLight";
  color: #262626;
`

export default injectIntl(LyneHomeMaisonSection)
