import React from "react"
import styled from "@emotion/styled"
import { isBrowser } from "../../../context/ApolloContext"
import Slider from "react-slick"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { injectIntl } from "gatsby-plugin-intl"

const RightArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button next"
      type="button"
      aria-label="Next"
      onClick={onClick}
    >
      <FaAngleRight size={30} />
    </button>
  )
}

const PrevArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button prev"
      type="button"
      aria-label="Previous"
      onClick={onClick}
    >
      <FaAngleLeft size={30} />
    </button>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  variableWidth: true,
  slidesToScroll: 1,
  nextArrow: <RightArrows />,
  prevArrow: <PrevArrows />,
}

const LyneHomeActivitySlider = ({ intl, data }) => {
  return (
    <LyneHomeActivitySliderWrapper>
      {isBrowser && (
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <ActivityBox key={index.toString()}>
                <ActivityImage className="lazyload" data-src={item.image} />
                <ContentBox>
                  {item.text && (
                    <ActivityTitle
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  )}
                </ContentBox>
              </ActivityBox>
            )
          })}
        </Slider>
      )}
    </LyneHomeActivitySliderWrapper>
  )
}

const LyneHomeActivitySliderWrapper = styled.div`
  display: flex;
  width: 100%;

  .slick-slider {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  .slick-dots li {
    width: 15px;
    height: 15px;
  }
  .slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0px;
  }
  .slick-dots li button:before {
    width: 15px;
    height: 15px;
    font-size: 10px;
    line-height: 15px;
  }
  .slider-prev-next-button {
    border: 2px solid #262626;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    bottom: -32px;
    z-index: 1;
  }
  .slider-prev-next-button.prev {
    left: 35%;

    @media (max-width: 767px) {
      left: 25%;
    }

    @media (max-width: 600px) {
      left: 0px;
    }
  }
  .slider-prev-next-button.next {
    right: 35%;

    @media (max-width: 767px) {
      right: 25%;
    }

    @media (max-width: 600px) {
      right: 0px;
    }
  }
`

const ActivityBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 210px;
  min-width: 210px;
  margin: 20px 20px 20px 0px;
  border-radius: 30px 0px 40px 0px;
  position: relative;
  cursor: pointer;
  align-items: center;

  @media (max-width: 420px) {
    margin: 20px 20px 20px 0px;
  }
`

const ActivityImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
`

const ContentBox = styled.div`
  padding: 5px 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ActivityTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham";
  font-weight: bold;
  color: #262626;
  text-transform: uppercase;
`

export default injectIntl(LyneHomeActivitySlider)
